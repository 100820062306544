@import '../../../style/variables.scss';

.editor-sub-section {
	display: grid;
	grid-template-columns: 32px 1fr;
	grid-template-rows: 1fr;
	column-gap: 16px;

	label,
	input {
		display: block;
	}

	&__delete-button {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
		width: 32px;
		height: 32px;
	}

	&__children {
		grid-column: 2 / 3;
		grid-row: 1 / 2;
		padding-bottom: 16px;
		border-bottom: 1px solid $border-color;
	}

	.form-item:first-of-type {
		margin-top: 0;
	}

	.form-item:last-of-type {
		margin-bottom: 0;
	}
}

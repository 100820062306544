@import '../../../style/variables.scss';

$base-height: 32px;
$border-width: 1px;
$padding: 16px;
$toolbar-height: $base-height + 2 * $padding + $border-width;

.page {
	width: 100%;
	max-width: 100vw;
	overflow-x: hidden;

	&__toolbar {
		align-items: center;
		background-color: white;
		border-bottom: $border-width solid $border-color;
		display: flex;
		flex-direction: row;
		height: $toolbar-height;
		left: 0;
		padding: $padding;
		padding-left: 64px + $padding; // Make room for sidebar toggle button
		position: fixed;
		top: 0;
		width: 100vw;
		z-index: 5;

		div {
			margin-left: 1rem;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	&__content {
		max-width: 100vw;
		width: 100%;
	}

	&__content--toolbar {
		padding-top: $toolbar-height;
	}

	&__toolbar--desktop {
		padding-left: $padding;
		left: $sidebar-width;
	}

	&__content--desktop {
		max-width: calc(100vw - 224px);
	}
}

.browse-view {
	h1 {
		display: block;
		padding: 32px 32px 0;
	}

	&__cards {
		padding: 32px;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
		grid-gap: 16px;

		a {
			text-decoration: none;
		}

		.p-card {
			color: white;
		}
	}
}

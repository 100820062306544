.register-view {
	h1 {
		display: block;
		padding: 32px 32px 0;
	}

	form {
		display: flex;
		flex-direction: column;
		width: 320px;
		padding: 32px;

		.p-inputtext,
		.p-button {
			margin-bottom: 16px;
		}

		.p-button {
			align-self: flex-start;
		}
	}
}

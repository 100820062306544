@import '../../../style/variables.scss';

.edit-view {
	display: flex;
	flex-direction: row;
	overflow: hidden;
	position: fixed;
	max-height: 100vh;

	h2 {
		padding: 4px 0;
	}

	h3 {
		padding: 4px 0;
		border-bottom: 1px solid $border-color;
	}

	.edit-view__edit-pane {
		flex: 1 100%;
		overflow-y: scroll;
	}

	.edit-view__preview-pane {
		overflow-y: scroll;
		flex: 1 100%;
		padding: 8px;

		svg {
			width: 100%;
			height: auto;
			border-radius: 8px;
		}
	}

	.form-item,
	.editor-sub-section {
		margin: 16px 0;
	}
}

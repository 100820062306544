@import '../../../style/variables.scss';

.sidebar {
	background-color: $header-bg-color;
	color: white;
	height: 100vh;
	overflow: hidden;
	width: 224px;
	z-index: 10;

	&__toggle-button {
		z-index: 11;
		position: fixed;
		top: 16px;
		left: 32px;
	}

	&--mobile {
		padding-top: 65px; // 64 + 1 for toolbar border
		position: fixed;
		top: 0;
		left: 0;
	}

	&__children {
		width: 224px;
		height: 100%;
		display: flex;
		flex-direction: column;

		h1 {
			font-size: 14px;
			line-height: 32px;
			font-weight: 700;
			text-transform: uppercase;
			padding: 8px 32px;
		}

		a {
			color: white;
			text-transform: uppercase;
			font-size: 14px;
			line-height: 16px;
			text-decoration: none;
			letter-spacing: 0.5px;
			padding: 16px 32px;
		}
	}

	&__active-link {
		border-left: 4px solid white;
		background-color: rgba(0, 0, 0, 0.2);
	}

	&__backdrop {
		z-index: 9;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(black, 0.75);
	}
}

@import '../../style/variables.scss';

.soft-stat-section {
	border-bottom: 1px solid $border-color;
	padding-bottom: 16px;
}

.soft-stat-section:last-of-type {
	border-bottom: none;
	padding-bottom: 0;
}

.soft-stat-section__add-button {
	display: flex;
	flex-direction: row;

	.p-button {
		margin-left: auto;
	}
}

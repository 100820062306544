@import '../../../style/variables.scss';

.app {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	display: flex;

	&__main {
		display: flex;
		flex-direction: auto;
		flex: 1 auto;
	}
}

@import './variables.scss';

@font-face {
	font-family: "Open Sans";
	font-weight: 300;
	src: url('../../assets/fonts/OpenSans-Light.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 300;
	font-style: italic;
	src: url('../../assets/fonts/OpenSans-LightItalic.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 400;
	src: url('../../assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 400;
	font-style: italic;
	src: url('../../assets/fonts/OpenSans-RegularItalic.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 500;
	src: url('../../assets/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 500;
	font-style: italic;
	src: url('../../assets/fonts/OpenSans-SemiBoldItalic.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 700;
	src: url('../../assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 700;
	font-style: italic;
	src: url('../../assets/fonts/OpenSans-BoldItalic.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 800;
	src: url('../../assets/fonts/OpenSans-ExtraBold.ttf');
}

@font-face {
	font-family: "Open Sans";
	font-weight: 800;
	font-style: italic;
	src: url('../../assets/fonts/OpenSans-ExtraBoldItalic.ttf');
}

@font-face {
	font-family: 'PT Sans';
	font-weight: 400;
	src: url('../../assets/fonts/PTSans-Regular.ttf');
}

@font-face {
	font-family: 'PT Sans';
	font-weight: 400;
	font-style: italic;
	src: url('../../assets/fonts/PTSans-RegularItalic.ttf');
}

@font-face {
	font-family: 'PT Sans';
	font-weight: 700;
	src: url('../../assets/fonts/PTSans-Bold.ttf');
}

@font-face {
	font-family: 'PT Sans';
	font-weight: 700;
	font-style: italic;
	src: url('../../assets/fonts/PTSans-BoldItalic.ttf');
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: none;
}

:root {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-size: 14px;
	line-height: 16px;

	--base-color: rgb(7, 7, 7);

	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	height: 100vh;
	position: absolute;
}

button {
	line-height: 16px;
}

h1 {
	font-size: 32px;
	line-height: 24px;
	font-weight: 300;
	color: rgb(128, 130, 132);
	letter-spacing: 0.5px;
}

h2 {
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
	font-weight: 600;
	color: rgb(128, 130, 132);
	letter-spacing: 0.5px;
}

h3 {
	font-size: 12px;
	line-height: 24px;
	text-transform: uppercase;
	font-weight: 600;
	color: rgb(128, 130, 132);
	letter-spacing: 0.5px;
}

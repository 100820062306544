@import '../../../style/variables.scss';

.card-view {
	padding: 96px 32px;

	svg {
		border-radius: 8px;
		width: 100%;
		height: auto;
	}
}
